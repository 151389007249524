<template>
    
<div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3">
  <div id="progressToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <!-- <img src="..." class="rounded me-2" alt="..."> -->
      <strong class="me-auto">Enviando al servidor...</strong>
      <!-- <small>11 mins ago</small> -->
      <!-- <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> -->
    </div>
    <div class="toast-body">
      Progreso: {{ stepPercent.toFixed(1) }}%
    </div>
  </div>
</div>

</template>

<script>
export default {
  props: {
    stepPercent: {
      type: Number,
      default: 0
    }
  }
}
</script>